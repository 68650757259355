<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        According to valence bond theory, what hybridization of a central atom is required to
        achieve a
        <stemble-latex :content="molGeometry" />
        molecular geometry?
      </p>

      <v-radio-group v-model="inputs.hybridization" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question258',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        hybridization: null,
      },
      options: [
        {expression: '$\\text{sp}$', value: 'sp'},
        {expression: '$\\text{sp}^2$', value: 'sp2'},
        {expression: '$\\text{sp}^3$', value: 'sp3'},
        {expression: '$\\text{sp}^4$', value: 'sp4'},
        {expression: '$\\text{sp}^2\\text{d}^2$', value: 'sp2d2'},
        {expression: '$\\text{sp}^3\\text{d}$', value: 'sp3d'},
        {expression: '$\\text{sp}^3\\text{d}^2$', value: 'sp3d2'},
        {expression: '$\\text{spd}^3$', value: 'spd3'},
        {expression: '$\\text{sp}^3\\text{d}^3$', value: 'sp3d3'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    molGeometry() {
      if (this.versionNumber.value === 1) {
        return '$\\textbf{square planar}$';
      } else if (this.versionNumber.value === 2) {
        return '$\\textbf{square pyramidal}$';
      } else if (this.versionNumber.value === 3) {
        return '$\\textbf{see-saw}$';
      } else {
        return 'ERROR';
      }
    },
  },
};
</script>
